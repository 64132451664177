import styled from 'styled-components';
import Link from 'components/Link';
import colors from 'constants/colors';
import icCloseBlack from '../../assets/icons/ic_close_black.svg';

export const StyledBar = styled.div`
  color: ${colors.main.black};
  padding: 12px 16px;
  text-align: center;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: underline;
    color: ${colors.main.black};
  }
`;

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
`;

export const CloseButton = styled.button`
  width: 25px;
  height: 25px;
  background: url(${icCloseBlack});
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px 15px 0 0;
  cursor: pointer;
`;
