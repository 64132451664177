import React, { useEffect } from 'react';
import { withRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import Section from 'components/core/Section';
import Text from 'components/core/Text';
import ActivityWidget from 'containers/Pages/Activities/ActivityWidget';
import ScheduledActivitiesList from 'components/ScheduledActivitiesList';
import useActivities from 'hooks/useActivities';
import BlogSection from 'components/BlogSection';
import AddProducts from 'components/AddProducts';
import { fetchPrograms } from 'actions/programs';
import { requestFetchAchievements } from 'actions/achievements';
import { fetchUser, updateUser } from 'actions/user';
import PageLoader from 'components/PageLoader';
import AnnouncementBar from 'components/AnnouncementBar';
import sortPrograms from '../../../../utils/sortPrograms';
import Container from '../Container';
import PurchasedPrograms from '../../../PurchasedPrograms';
import SessionInfo from '../../../SessionInfo';
import { Wrapper } from '../../../../constants/globalStyles';

function Content({
  router,
  programs,
  showModal,
  lastUserWorkoutSessionDate,
  clearExercisesState,
  isResumingSession,
  getProductDataRequest,
}) {
  const dispatch = useDispatch();
  const featuredProgram = programs?.total ? sortPrograms(programs.all)[0] : {};
  const { futureActivities } = useActivities();
  const isProgramsLoading = useSelector(state => state.programs.loading);
  const user = useSelector(state => state.user);
  const announcement = useSelector(state => state.advertisement.announcement);

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchPrograms());
    dispatch(requestFetchAchievements());
  }, [dispatch]);

  useEffect(() => {
    const productId = router.query?.['purchased-product'];
    if (productId) {
      getProductDataRequest({ productId });
    }
  }, [router.query, getProductDataRequest]);

  const handleAnnouncementClose = () => {
    dispatch(
      updateUser({
        data: {
          settings: {
            ...user.settings,
            showAnnouncement: false,
          },
        },
      }),
    );
  };

  return (
    <Wrapper>
      {isProgramsLoading ? (
        <PageLoader />
      ) : (
        <Section fillViewport={false} mb={4} pb={6}>
          <PurchasedPrograms
            clearExercisesState={clearExercisesState}
            programs={programs}
            lastUserWorkoutSessionDate={lastUserWorkoutSessionDate}
            isResumingSession={isResumingSession}
            showModal={showModal}
          />

          <Container>
            <SessionInfo program={featuredProgram} schedule={featuredProgram.schedule} />
          </Container>
        </Section>
      )}
      {user.settings?.showAnnouncement && announcement && (
        <Section fillViewport={false} mb={4}>
          <Container>
            <AnnouncementBar
              message={announcement.message}
              linkText="Check it out"
              linkUrl="/programs/new"
              onClose={handleAnnouncementClose}
            />
          </Container>
        </Section>
      )}

      {!!futureActivities.length && (
        <Section fillViewport={false} mb={4} py={6}>
          <Container>
            <Text variant="sectionHeader" transform="uppercase" mb={4}>
              Scheduled sessions
            </Text>
            <ScheduledActivitiesList list={futureActivities.reverse()} />
          </Container>
        </Section>
      )}
      <Section fillViewport={false} mb={4} py={6}>
        <Container>
          <ActivityWidget withColumns />
        </Container>
      </Section>
      <AddProducts />
      <BlogSection />
    </Wrapper>
  );
}

export default withRouter(Content);
